import { useEffect, useState, MouseEvent } from 'react';
import { mixed, object, string } from 'yup';
import styles from './emails.module.scss';
import SteppedForm from '../../components/stepped-form/stepped-form';
import FormStep from '../../components/stepped-form/step';
import FormFooter from '../../components/stepped-form/footer';
import Select from '../../components/select/select';
import DragFile from '../../components/drag-file/drag-file.component';

const EmailsView = () => {
    const [formData, setFormData] = useState({
        template: '',
        file: undefined,
    });
    const [validForm, setValidForm] = useState(false);

    useEffect(() => {
        validateFormData();
    }, [formData]);

    const addFormData = (attr: string, value: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [attr]: value,
        }));
    };

    const validateFormData = async () => {
        const schema = object({
            template: string().min(3).required(),
            file: mixed().required(),
        });
        setValidForm(await schema.isValid(formData));
    };

    const sendEmail = (ev: MouseEvent) => {
        ev.preventDefault();
        console.log('data:', formData);
    };

    return (
        <main id={styles.emails}>
            <header className={styles.viewHeader}>
                <article>
                    <h3>Correos electrónicos</h3>
                    <p>
                        Envía correos electrónicos de manera masiva para
                        estrategias de marketing y publicidad.
                    </p>
                </article>
            </header>
            <section className={styles.body}>
                <SteppedForm>
                    <FormStep count={1} title={'¿Qué template utilizaremos?'}>
                        <Select
                            options={[
                                {
                                    value: '-1',
                                    text: 'Selecciona un template',
                                    disabled: true,
                                },
                                {
                                    value: 'luis-template',
                                    text: 'luis-template',
                                },
                                { value: 'dev', text: 'dev' },
                            ]}
                            onChange={(value) => addFormData('template', value)}
                        />
                    </FormStep>
                    <FormStep count={2} title={'¿A quiénes lo enviamos?'}>
                        <label className={styles.sectionDescription}>
                            Añade el archivo con la lista de destinatarios
                            (direcciones de correo electrónico)
                        </label>
                        <DragFile
                            sizeLimit={300}
                            onFileSelected={(file) => addFormData('file', file)}
                        />
                    </FormStep>
                    <FormFooter>
                        <div className={styles.formFooter}>
                            <button disabled={!validForm} onClick={sendEmail}>
                                Enviar
                            </button>
                        </div>
                    </FormFooter>
                </SteppedForm>
            </section>
        </main>
    );
};

export default EmailsView;
