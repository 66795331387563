import ILogin from '../models/login.model';
import IWebserviceResponse from '../models/webservice-response.model';
import { webservice } from './webservice';

const BASE_URL = process.env.REACT_APP_AUTH_URL;

const logIn = async (credentials: ILogin): Promise<IWebserviceResponse> => {
    try {
        const response = await webservice.httpClient.post(
            `${BASE_URL}/api/v1/auth/operators`,
            credentials
        );
        return response.data;
    } catch (err: any) {
        console.error('PnsWebservice error:', err);
        return webservice.handleResponseError(err);
    }
};

export const authSDK = {
    logIn,
};
