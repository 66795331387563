import styles from './stepped-form.module.scss';
import { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<ReactNode> & {
    count: number;
    title: string;
};

const FormStep = (props: Props) => {
    return (
        <section className={styles.step}>
            <header className={styles.header}>
                <div className={styles.counter}>{props.count}</div>
                <h3>{props.title}</h3>
            </header>
            <div className={styles.content}>{props.children}</div>
        </section>
    );
};

export default FormStep;
