import { useEffect, useState, MouseEvent } from 'react';
import styles from './push-notifications.module.scss';
import DragFile from '../../components/drag-file/drag-file.component';
import SchedulingPicker from '../../components/scheduling-picker/scheduling-picker.component';
import Input from '../../components/input/input.component';
import { object, string, mixed } from 'yup';
import PushNotificationReviewModal from '../../components/push-notification-review-modal/push-notification-review-modal.component';
import PnsWebservice from '../../webservices/pns.webservice';
import IPushNotificationForm from '../../models/push-notification-form.model';

const FILE_SIZE_LIMIT = 300; // expressed in KB

const PushNotificationsView = () => {
    const pnsWebservice = new PnsWebservice();
    const [reviewButtonEnable, setReviewButtonEnable] = useState(false);
    const [formData, setFormData] = useState<IPushNotificationForm>({
        notificationTitle: '',
        notificationBody: '',
        file: null,
        scheduledDate: '',
        scheduledTime: '',
    });
    const [modalShown, setModalShown] = useState(false);
    const [dialogStatus, setDialogStatus] = useState('error');
    const [dialogShown, setDialogShown] = useState(false);
    const [dialogText, setDialogText] = useState('');

    useEffect(() => {
        validateFormData();
    }, [formData]);

    const reviewForm = (ev: MouseEvent) => {
        ev.preventDefault();
        setModalShown(true);
    };

    const addFormData = (attr: string, value: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [attr]: value,
        }));
    };

    const validateFormData = async () => {
        const schema = object({
            notificationTitle: string().min(3).required(),
            notificationBody: string().min(3).required(),
            file: mixed().required(),
        });
        setReviewButtonEnable(await schema.isValid(formData));
    };

    const publishNotification = async (notification: IPushNotificationForm) => {
        const date = new Date(
            `${notification.scheduledDate}T${notification.scheduledTime}`
        );
        notification.scheduledDate = date.getTime().toString();
        const response = await pnsWebservice.publishNotificationJob({
            notificationTitle: notification.notificationTitle,
            notificationBody: notification.notificationBody,
            file: notification.file!,
            scheduledDate: notification.scheduledDate,
        });
        setModalShown(false);
        if (response.code === 0) {
            setDialogStatus('success');
            setDialogText('Notificación publicada exitosamente');
        } else {
            setDialogStatus('error');
            setDialogText(
                `Error al publicar la notificación push: ${response.message}`
            );
        }
        showAlertDialog();
    };

    const showAlertDialog = () => {
        setDialogShown(true);
        setTimeout(() => {
            setDialogShown(false);
        }, 2500);
    };

    return (
        <main id={styles.pushNotifications}>
            <header className={styles.viewHeader}>
                <article>
                    <h3>Notificaciones push</h3>
                    <p>
                        Envía notificaciones push a dispositivos Android y iOS
                    </p>
                </article>
            </header>
            <section className={styles.body}>
                <form>
                    <section className={styles.step}>
                        <header className={styles.stepHeader}>
                            <div className={styles.stepCounter}>1</div>
                            <h3>Estilo de la notificación</h3>
                        </header>
                        <div className={styles.stepContent}>
                            <Input
                                label={'Título'}
                                placeholder={'Título'}
                                type={'text'}
                                maxlength={40}
                                className={styles.inputContainer}
                                onChange={(value) =>
                                    addFormData('notificationTitle', value)
                                }
                            />

                            <Input
                                label={'Cuerpo'}
                                placeholder={'Cuerpo'}
                                type={'textarea'}
                                maxlength={100}
                                className={styles.inputContainer}
                                onChange={(value) =>
                                    addFormData('notificationBody', value)
                                }
                            />
                        </div>
                    </section>

                    <section className={styles.step}>
                        <header className={styles.stepHeader}>
                            <div className={styles.stepCounter}>2</div>
                            <h3>Segmento</h3>
                        </header>
                        <div className={styles.stepContent}>
                            <div className={styles.inputContainer}>
                                <label className={styles.sectionDescription}>
                                    Indica la lista de usuarios a ser
                                    notificados
                                </label>
                                <DragFile
                                    sizeLimit={FILE_SIZE_LIMIT}
                                    onFileSelected={(file) =>
                                        addFormData('file', file)
                                    }
                                />
                            </div>
                        </div>
                    </section>

                    <section className={styles.step}>
                        <header className={styles.stepHeader}>
                            <div className={styles.stepCounter}>3</div>
                            <h3>Programación</h3>
                        </header>
                        <div className={styles.stepContent}>
                            <div className={styles.inputContainer}>
                                <label className={styles.sectionDescription}>
                                    ¿Para cuándo será el envío?
                                </label>
                                <SchedulingPicker
                                    onDateChange={(date) =>
                                        addFormData('scheduledDate', date)
                                    }
                                    onTimeChange={(time) =>
                                        addFormData('scheduledTime', time)
                                    }
                                />
                            </div>
                        </div>
                    </section>

                    <footer>
                        <button
                            disabled={!reviewButtonEnable}
                            onClick={reviewForm}
                        >
                            Revisar
                        </button>
                    </footer>
                </form>
            </section>

            <PushNotificationReviewModal
                shown={modalShown}
                notificationForm={formData}
                onDiscard={() => setModalShown(false)}
                onPublish={publishNotification}
            />

            <div
                className={`${styles.alertDialog} ${styles[dialogStatus]} ${
                    dialogShown ? styles.shown : ''
                }`}
            >
                <p>{dialogText}</p>
            </div>
        </main>
    );
};

export default PushNotificationsView;
