import { create } from 'zustand';
import { RemoteConfig } from './types/remote-config.type';
import { DefaultRemoteConfig } from './utils/default-remote-config.util';

interface RemoteConfigState {
    config: RemoteConfig;
    setConfig: (config?: RemoteConfig) => void;
}

export const useRemoteConfigStore = create<RemoteConfigState>((set) => ({
    config: DefaultRemoteConfig.pns_dev,
    setConfig: (config) => set({ config }),
}));
