import axios, { Axios, AxiosError } from 'axios';
import IWebserviceResponse from '../models/webservice-response.model';

const httpClient: Axios = axios;

const handleResponseError = (error: AxiosError): IWebserviceResponse => {
    if (!error.response) {
        console.error('[!] Internet connection error');
        return {
            code: -999,
            message: 'Internet connection error',
        };
    }

    return error.response.data as IWebserviceResponse;
};

export const webservice = {
    httpClient,
    handleResponseError,
};
