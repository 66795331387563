import styles from './select.module.scss';

interface Props {
    options: {
        value: any;
        text: string;
        disabled?: boolean;
    }[];
    onChange?: (value: any) => void;
}

const Select = (props: Props) => {
    return (
        <select
            className={styles.select}
            onChange={(ev) => props.onChange?.(ev.target.value)}
            defaultValue={'Selecciona un template'}
        >
            {props.options.map((option, idx) => (
                <option
                    key={idx}
                    defaultValue={option.value}
                    disabled={option.disabled}
                >
                    {option.text}
                </option>
            ))}
        </select>
    );
};

export default Select;
