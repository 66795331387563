import { ReactNode } from 'react';
import HomeView from './views/home/home.view';
import LoginView from './views/login/login.view';
import NotFoundView from './views/not-found/not-found.view';
import PushNotificationsView from './views/push-notifications/push-notifications.view';
import EmailsView from './views/emails/emails.view';

interface IView {
    path: string;
    element: ReactNode;
    protected: boolean;
}

export const Views: IView[] = [
    { path: '/', element: <HomeView />, protected: true },
    { path: '/login', element: <LoginView />, protected: false },
    { path: '/push', element: <PushNotificationsView />, protected: true },
    { path: '/emails', element: <EmailsView />, protected: true },
    { path: '*', element: <NotFoundView />, protected: false },
];
