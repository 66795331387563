import React, { useEffect } from 'react';
import styles from './App.module.scss';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/protected-route/protected-route.component';
import { Views } from './views';
import remoteConfigSDK from '@pana-tech/remote-config-sdk';
import { DefaultRemoteConfig } from './utils/default-remote-config.util';
import { Envs } from './types/envs.type';
import { RemoteConfig } from './types/remote-config.type';
import { useRemoteConfigStore } from './store';

function App() {
    const { setConfig } = useRemoteConfigStore();
    useEffect(() => {
        initRemoteConfig();
    }, []);

    const initRemoteConfig = async () => {
        await remoteConfigSDK.init({
            token: 'IaCYrg.DAgZ-A:RFUgLoCmmWrp4KtFKgQfjD4NIETvwYTj2paUfESSCMs',
            env: process.env.ENV as Envs,
            defaultValue: DefaultRemoteConfig,
        });
        setConfig(remoteConfigSDK.get<RemoteConfig>('pns_dev'));
    };

    return (
        <div id={styles.app}>
            <Routes>
                {Views.filter((view) => !view.protected).map((view, idx) => (
                    <Route key={idx} path={view.path} element={view.element} />
                ))}
                {/*<Route path={'login'} element={<LoginView />} />*/}
                {/* Authenticated routes*/}
                <Route element={<ProtectedRoute />}>
                    {Views.filter((view) => view.protected).map((view, idx) => (
                        <Route
                            key={idx}
                            path={view.path}
                            element={view.element}
                        />
                    ))}
                </Route>
                {/*<Route path={'*'} element={<NotFoundView />} />*/}
            </Routes>
        </div>
    );
}

export default App;
