import styles from './service-card.module.scss';

interface Props {
    active: boolean;
    title: string;
    content: string;
    img: string;
    onClick?: () => void;
}

const ServiceCard = (props: Props) => {
    const onClick = () => {
        props.active && props.onClick && props.onClick();
    };

    return (
        <div
            className={`${styles.serviceCard} ${
                !props.active ? styles.disabled : ''
            }`}
            onClick={() => onClick()}
        >
            <img src={props.img} alt={'sms'} />
            <section>
                <h3>
                    {props.title}
                    {!props.active ? ' (inhabilitado)' : ''}
                </h3>
                <p>{props.content}</p>
            </section>
        </div>
    );
};

export default ServiceCard;
