import React, { useContext, useEffect, useState } from 'react';
import { object, string } from 'yup';

import Input from '../../components/input/input.component';
import ILogin from '../../models/login.model';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/context';
import { authSDK } from '../../webservices/auth.webservice';

import styles from './login.module.scss';

const LoginView = () => {
    const navigate = useNavigate();
    const { globalState, dispatch } = useContext(GlobalContext);
    const [credentials, setCredentials] = useState<ILogin | null>(null);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [buttonText, setButtonText] = useState('Continuar');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (globalState.accessToken) {
            navigate('/', { replace: true });
        }
    }, [globalState.accessToken]);

    useEffect(() => {
        if (credentials) {
            checkValidCredentials(credentials);
        }
    }, [credentials]);

    const checkValidCredentials = async (credentials: ILogin) => {
        const schema = object({
            email: string().email().required(),
            password: string().min(8).required(),
        });
        setButtonEnabled(await schema.isValid(credentials));
    };

    const fillCredentials = (attr: string, value: string) => {
        setCredentials((prevState: any) => ({
            ...prevState,
            [attr]: value,
        }));
    };

    const login = async (ev: React.MouseEvent) => {
        ev.preventDefault();
        setButtonEnabled(false);
        setButtonText('Cargando...');
        setErrorMessage('');

        const response = await authSDK.logIn(credentials!);
        if (response.code !== 0) {
            setButtonEnabled(true);
            setButtonText('Continuar');
            setErrorMessage(response.message);
            return;
        }

        dispatch({ action: 'login', data: response.data });
    };

    return (
        <main id={styles.loginView}>
            <header />
            <form>
                <h2>Inicio de sesión</h2>

                <Input
                    label={'Correo'}
                    placeholder={'Correo'}
                    type={'email'}
                    onChange={(value) => fillCredentials('email', value)}
                    className={styles.input}
                />

                <Input
                    label={'Contraseña'}
                    placeholder={'Contraseña'}
                    type={'password'}
                    onChange={(value) => fillCredentials('password', value)}
                    className={styles.input}
                />

                <p
                    className={`${styles.errorMessage} ${
                        errorMessage ? styles.shown : ''
                    }`}
                >
                    {errorMessage}
                </p>

                <button disabled={!buttonEnabled} onClick={login}>
                    {buttonText}
                </button>
            </form>
        </main>
    );
};

export default LoginView;
