import styles from './drag-file.module.scss';
import { MouseEvent, ChangeEvent, useEffect, useRef, useState } from 'react';
import DenyIcon from '../../icons/deny.icon';

interface Props {
    sizeLimit: number;
    onFileSelected: (file: File | null) => void;
}

const DragFile = (props: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    useEffect(() => {
        props.onFileSelected(uploadedFile);
    }, [uploadedFile]);

    const searchFile = (ev: MouseEvent) => {
        ev.preventDefault();
        inputRef.current?.click();
    };

    const onFileUploaded = (ev: ChangeEvent) => {
        const input = ev.target as HTMLInputElement;
        if (!input.files || input.files.length === 0) return;

        if (isValidFile(input.files[0])) {
            setUploadedFile(input.files[0]);
        } else {
            setUploadedFile(null);
        }
    };

    const onFileDropped = (ev: React.DragEvent) => {
        ev.preventDefault();

        const items = ev.dataTransfer.items;
        if (!items || items.length === 0) return;

        const file = items[0].getAsFile();
        if (file && isValidFile(file)) {
            setUploadedFile(file);
        } else {
            setUploadedFile(null);
        }
    };

    const isValidFile = (file: File): boolean => {
        const splitName = file.name.split('.');
        // Checking file extension
        if (splitName[splitName.length - 1] !== 'csv') return false;

        // Checking file size
        return file.size / 1024 <= props.sizeLimit;
    };

    const onDropOver = (ev: React.DragEvent) => {
        ev.preventDefault();
    };

    const removeFile = () => {
        setUploadedFile(null);
        inputRef.current!.files = null;
        inputRef.current!.value = '';
    };

    return (
        <div
            onDrop={onFileDropped}
            onDragOver={onDropOver}
            className={styles.container}
        >
            {uploadedFile ? (
                <div className={styles.setFile}>
                    <p>{uploadedFile.name}</p>
                    <div
                        className={styles.iconContainer}
                        onClick={() => removeFile()}
                    >
                        <DenyIcon color={'#1a73e8'} strokeWidth={2} />
                    </div>
                </div>
            ) : (
                <div className={styles.noFile}>
                    <article>
                        <h3>Arrasta el archivo hasta aquí</h3>
                        <p>
                            Archivos permitidos: CSV <br />
                            Hasta {props.sizeLimit} KB
                        </p>
                    </article>
                    <button onClick={searchFile}>Buscar</button>
                </div>
            )}

            <input
                type={'file'}
                ref={inputRef}
                accept={'.csv, application/vnd.ms-excel'}
                onChange={onFileUploaded}
            />
        </div>
    );
};

export default DragFile;
