export const DefaultRemoteConfig = {
    pns_dev: {
        emails: {
            enabled: true,
        },
        pushNotifications: {
            enabled: true,
        },
        sms: {
            enabled: false,
        },
    },
};
