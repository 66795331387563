import styles from './home.module.scss';
import SMSImage from '../../assets/img/sms.png';
import PushImage from '../../assets/img/push-notification.png';
import ServiceCard from '../../components/service-card/service-card.component';
import { useNavigate } from 'react-router-dom';
import { useRemoteConfigStore } from '../../store';

const HomeView = () => {
    const { config } = useRemoteConfigStore();
    const navigate = useNavigate();

    const goToView = (view: string) => {
        navigate(view);
    };

    return (
        <main id={styles.home}>
            <header>
                <article>
                    <h1>Servicio de Mensajería</h1>
                    <p>
                        Envío de notificaciones push, <br />
                        mensajes de texto (SMS) y <br />
                        próximamente emails
                    </p>
                </article>
            </header>

            <section className={styles.content}>
                <ServiceCard
                    active={config.sms.enabled}
                    title={'Mensajería SMS'}
                    content={
                        'Envío de mensajes de texto a través del protocolo SMS gracias a Twilio.'
                    }
                    img={SMSImage}
                    onClick={() => goToView('/sms')}
                />

                <ServiceCard
                    active={config.pushNotifications.enabled}
                    title={'Notificaciones Push'}
                    content={
                        'Envío masivo de notificaciones push a los dispositivos Android y iOS.'
                    }
                    img={PushImage}
                    onClick={() => goToView('/push')}
                />

                <ServiceCard
                    active={config.emails.enabled}
                    title={'Emails'}
                    content={
                        'Envío de correos electrónicos a nuestra base de datos de usuarios.'
                    }
                    img={PushImage}
                    onClick={() => goToView('/emails')}
                />
            </section>
        </main>
    );
};

export default HomeView;
